<template>
  <!-- 合同详情 -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>

    <div class="top-title">
      <span> {{ contractDetail.contractName }}</span>
    </div>

    <div class="contract-btn">
      <el-row :gutter="10">
        <!-- E签宝合同 -->
        <el-col :span="12">
          <div class="contract-item">
            <span>E签宝合同</span>
            <span v-for="item in esignContractList" :key="item.contractUrl">
              <el-button type="primary" size="mini" @click="downloadContract(item)">{{ item.contractName }}</el-button>
            </span>
          </div>
        </el-col>
        <!-- 租好租合同 -->
        <el-col :span="12">
          <div class="contract-item">
            <span>租好租合同</span>
            <span v-for="item in zhzContractList" :key="item.contractUrl">
              <el-button type="primary" size="mini" @click="downloadContract(item)">{{ item.contractName }}</el-button>
            </span>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="contract-btn">
      <div class="contract-row">
        <span class="title">公证</span>
      </div>
      <div class="contract-row">
        <span>公证链接</span>
        <span>
          <el-button :disabled="isDisabledNotaryUrl" type="primary" size="mini" @click="getNotaryUrl">获取</el-button>
        </span>
      </div>
      <div class="contract-row">
        <span>公证证书</span>
        <span>
          <el-button :disabled="isDisabledNotaryFile" type="primary" size="mini" @click="downloadNotary">下载</el-button>
        </span>
      </div>
    </div>

    <div class="contract-btn">
      <div class="contract-row">
        <span>征信报告</span>
        <span class="report-btn" @click="goCreditReport" v-checkbtn="isShowBtn(AUTH_BTN.order_list_credit_report)">
          <el-button v-if="!isShowReport" :disabled="isShowReport" type="primary" size="mini">查看</el-button>
          <div v-else class="report_null">查看</div>
        </span>
      </div>
    </div>
    <div style="height:50px;"></div>
    <!--  -->
    <el-dialog title="公证链接" :visible.sync="dialogVisible" width="700px">
      <div class="link">
        <span>公证链接：</span>
        <span>{{ notaryLink }}</span>
        <span>
          <el-button type="primary" size="mini" @click="copyLink">复制</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { dateFormat } from '@/utils/index.js'
import { getContractDetailAPI, getNotaryApplyAPI, downloadPDF } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'
export default {
  name: 'ContactDetail',
  mixins: [authBtnMixin],
  data() {
    return {
      dialogVisible: false,
      contractDetail: {},
      notaryLink: ''
    }
  },
  computed: {
    esignContractList() {
      let list = []
      if (this.contractDetail.esignContractList) {
        list = this.contractDetail.esignContractList
      }
      return list || []
    },
    zhzContractList() {
      let list = []
      if (this.contractDetail.zhzContractList) {
        list = this.contractDetail.zhzContractList
      }
      return list || []
    },
    notaryInfo() {
      let info = {}
      if (this.contractDetail.notaryInfo) {
        info = this.contractDetail.notaryInfo
      }
      return info || {}
    },
    creditInfo() {
      let info = {}
      if (this.contractDetail.creditInfo) {
        info = this.contractDetail.creditInfo
      }
      return info
    },
    isShowReport() {
      let show = true
      const { enableCredit, creditQueryStatus } = this.creditInfo
      if (!enableCredit) {
        show = true
      } else if (enableCredit && creditQueryStatus == 11) {
        show = false
      }
      return show
    },
    isDisabledNotaryUrl() {
      let status = true
      if (this.contractDetail.notaryInfo) {
        const { enableNotary, notaryStatus } = this.contractDetail.notaryInfo
        if (!enableNotary) {
          status = true
        } else if (enableNotary && notaryStatus == 0) {
          status = false
        }
      }

      return status
    },
    isDisabledNotaryFile() {
      let status = true
      if (this.contractDetail.notaryInfo) {
        const { enableNotary, notaryStatus } = this.contractDetail.notaryInfo
        if (!enableNotary) {
          status = true
        } else if (enableNotary && notaryStatus == 5) {
          status = false
        }
      }

      return status
    }
  },
  mounted() {
    this.getContractDetail()
  },

  methods: {
    async getContractDetail() {
      this.contractDetail = await getContractDetailAPI(this.$route.params.id)
    },
    // 获取公证链接
    getNotaryUrl() {
      getNotaryApplyAPI(this.$route.params.id).then(res => {
        this.notaryLink = res.applyUrl
        this.dialogVisible = true
      })
    },
    copyLink() {
      this.$copyText(this.notaryLink).then(
        () => {
          this.$message.success('复制成功')
        },
        () => {
          this.$message.error('复制失败')
        }
      )
    },
    // 合同下载
    downloadContract(item) {
      const { contractName, contractUrl } = item
      this.$confirm(`此操作将下载【${contractName}】, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const name = `${this.$route.params.id}_${contractName}_${dateFormat()}.pdf`
          downloadPDF(contractUrl, name)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },
    // 证书下载
    downloadNotary() {
      this.$confirm(`此操作将下载该订单公证证书, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const name = `${this.$route.params.id}_公证证书_${dateFormat()}.pdf`
          downloadPDF(this.notaryInfo.notaryCertUrl, name)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },
    goCreditReport(e) {
      if (this.isShowReport) {
        this.$alert('征信报告未获取成功、请等待！', '提示', {
          confirmButtonText: '确定',
          callback: action => {},
          type: 'warning'
        })
        return
      }

      let link = this.$router.resolve({ name: `OrderCreditReport`, params: { id: this.$route.params.id } })
      window.open(link.href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .top-title {
    text-align: center;
    margin-bottom: 10px;
    span {
      display: inline-block;
      font-size: 18px;
      font-weight: 600;
    }
  }
  .contract-btn {
    background-color: #fafafa;
    border-radius: 5px;
    padding: 20px 10px;
    margin: 10px 0;
    .contract-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      span {
        margin: 10px 0;
        &:first-child {
          font-weight: 700;
          // font-size: 16px;
        }
      }
    }
    .contract-row {
      display: flex;
      margin: 10px 0;
      span {
        margin-right: 30px;
      }
      .report-btn /deep/ {
        display: flex;
        .el-button {
          padding: 0;
          span {
            display: inline-block;
            padding: 7px 15px;
          }
        }
        .report_null {
          color: #fff;
          background-color: #a0cfff;
          border-color: #a0cfff;
          cursor: not-allowed;
          width: 56px;
          height: 28px;
          line-height: 28px;
          text-align: center;
          font-size: 12px;
          border-radius: 3px;
        }
      }
    }
    .title {
      font-weight: 700;
      // font-size: 16px;
    }
  }
  .link {
    margin-bottom: 50px;
    height: 80px;
    display: flex;
    align-items: center;
    & > span {
      font-size: 16px;

      &:nth-child(2) {
        margin-right: 10px;
        border-radius: 2px;
        padding: 2px 10px;
        background-color: #fafafa;
        text-decoration: underline;
      }
    }
  }
}
</style>
